export default function KubigRedLogo() {
  return (
    <svg
      width="295"
      height="116"
      viewBox="0 0 295 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1585_10985)">
        <path
          d="M67.9368 10L44.1863 58.0766L67.7568 105.847H50.1239L27.2731 58.0766L50.4838 10H67.9368ZM10 105.847V10H26.1935V105.847H10Z"
          fill="#9E0000"
        />
        <path
          d="M108.742 10H125.116V92.3732C125.116 101.356 119.478 105.847 108.203 105.847H88.2306C76.9552 105.847 71.3174 101.356 71.3174 92.3732V10H87.6909V90.2297C87.6909 92.067 88.0507 93.2919 88.7704 93.9043C89.4901 94.4147 90.9296 94.6698 93.0887 94.6698H103.345C105.624 94.6698 107.063 94.4147 107.663 93.9043C108.383 93.2919 108.742 92.067 108.742 90.2297V10Z"
          fill="#9E0000"
        />
        <path
          d="M175.759 47.2057V25.311C175.759 23.4737 175.399 22.2998 174.679 21.7895C173.959 21.177 172.52 20.8708 170.361 20.8708H152.368V51.6459H170.361C172.52 51.6459 173.959 51.3907 174.679 50.8804C175.399 50.2679 175.759 49.0431 175.759 47.2057ZM175.759 90.5359V66.9569C175.759 65.1196 175.399 63.9458 174.679 63.4354C173.959 62.823 172.52 62.5167 170.361 62.5167H152.368V94.9761H170.361C172.52 94.9761 173.959 94.7209 174.679 94.2105C175.399 93.5981 175.759 92.3732 175.759 90.5359ZM136.174 10H175.219C186.494 10 192.132 14.4912 192.132 23.4737V47.8182C192.132 53.2281 189.733 56.3923 184.935 57.311C189.733 58.3317 192.132 61.2919 192.132 66.1914V92.3732C192.132 101.356 186.494 105.847 175.219 105.847H136.174V10Z"
          fill="#9E0000"
        />
        <path d="M204.04 10H220.233V105.847H204.04V10Z" fill="#9E0000" />
        <path
          d="M285 38.0191H268.627V25.311C268.627 23.4737 268.267 22.2998 267.547 21.7895C266.827 21.177 265.388 20.8708 263.229 20.8708H254.052C251.893 20.8708 250.454 21.177 249.734 21.7895C249.014 22.2998 248.655 23.4737 248.655 25.311V90.689C248.655 92.5263 249.014 93.7512 249.734 94.3636C250.454 94.874 251.893 95.1292 254.052 95.1292H263.229C265.388 95.1292 266.827 94.874 267.547 94.3636C268.267 93.7512 268.627 92.5263 268.627 90.689V67.2632H259.63V56.5455H285V92.3732C285 101.458 285 106 285 106H249.014C237.859 106 232.281 101.458 232.281 92.3732V23.4737C232.281 14.4912 237.859 10 249.014 10H268.267C279.422 10 285 14.4912 285 23.4737V38.0191Z"
          fill="#9E0000"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1585_10985"
          x="0"
          y="0"
          width="295"
          height="116"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1585_10985"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1585_10985"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

/*

<svg
      xmlns="http://www.w3.org/2000/svg"
      width="355"
      height="136"
      viewBox="0 0 355 136"
      fill="none"
    >
      <g filter="url(#filter0_d_506_1579)">
        <path
          d="M80.5775 10L51.6451 68.0925L80.3583 125.815H58.8782L31.0417 68.0925L59.3166 10H80.5775ZM10 125.815V10H29.7266V125.815H10Z"
          fill="#9E0000"
        />
        <path
          d="M130.286 10H150.232V109.534C150.232 120.388 143.364 125.815 129.629 125.815H105.299C91.5636 125.815 84.6958 120.388 84.6958 109.534V10H104.642V106.944C104.642 109.164 105.08 110.644 105.957 111.384C106.833 112.001 108.587 112.309 111.217 112.309H123.711C126.487 112.309 128.24 112.001 128.971 111.384C129.848 110.644 130.286 109.164 130.286 106.944V10Z"
          fill="#9E0000"
        />
        <path
          d="M211.924 54.9569V28.5008C211.924 26.2807 211.486 24.8623 210.609 24.2456C209.732 23.5056 207.979 23.1356 205.348 23.1356H183.43V60.3222H205.348C207.979 60.3222 209.732 60.0138 210.609 59.3971C211.486 58.6571 211.924 57.177 211.924 54.9569ZM211.924 107.314V78.823C211.924 76.6029 211.486 75.1845 210.609 74.5678C209.732 73.8277 207.979 73.4577 205.348 73.4577H183.43V112.679H205.348C207.979 112.679 209.732 112.371 210.609 111.754C211.486 111.014 211.924 109.534 211.924 107.314ZM163.703 10H211.266C225.002 10 231.87 15.4269 231.87 26.2807V55.697C231.87 62.2339 228.947 66.0574 223.102 67.1675C228.947 68.4009 231.87 71.9777 231.87 77.8979V109.534C231.87 120.388 225.002 125.815 211.266 125.815H163.703V10Z"
          fill="#9E0000"
        />
        <path d="M246.375 10H266.102V125.815H246.375V10Z" fill="#9E0000" />
        <path
          d="M345 43.8565H325.054V28.5008C325.054 26.2807 324.616 24.8623 323.739 24.2456C322.862 23.5056 321.109 23.1356 318.479 23.1356H307.3C304.67 23.1356 302.917 23.5056 302.04 24.2456C301.163 24.8623 300.725 26.2807 300.725 28.5008V107.499C300.725 109.719 301.163 111.199 302.04 111.939C302.917 112.556 304.67 112.864 307.3 112.864H318.479C321.109 112.864 322.862 112.556 323.739 111.939C324.616 111.199 325.054 109.719 325.054 107.499V79.193H314.095V66.2424H345V109.534C345 120.511 345 126 345 126H301.163C287.574 126 280.779 120.511 280.779 109.534V26.2807C280.779 15.4269 287.574 10 301.163 10H324.616C338.205 10 345 15.4269 345 26.2807V43.8565Z"
          fill="#9E0000"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_506_1579"
          x="0"
          y="0"
          width="355"
          height="136"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_506_1579"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_506_1579"
            result="shape"
          />
        </filter>
      </defs>
    </svg>

    */
